import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";
import { Infer, create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import GrowaveFeaturesBodySchema from "./GrowaveFeaturesBodySchema";

class GrowaveFeaturesProvider {
    constructor(private readonly logger: LoggerInterface) {}
    getFeatures(): Infer<typeof GrowaveFeaturesBodySchema> {
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );
        const features = create(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            (additionalInfoData as any).FEATURES,
            GrowaveFeaturesBodySchema
        );
        this.logger.debug("GrowaveFeaturesProvider.getFeatures", {
            features,
        });
        return features;
    }
}

export default GrowaveFeaturesProvider;

import { QueryEntity } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewsGalleryStore, {
    ReviewsGalleryState,
} from "../stores/ReviewsGalleryStore";

@injectable()
class ReviewsGalleryQuery extends QueryEntity<ReviewsGalleryState> {
    constructor(protected store: ReviewsGalleryStore) {
        super(store);
    }

    active$ = this.selectActive();
    all$ = this.selectAll();
    reviews$ = this.select((state) => state.entities);
}

export default ReviewsGalleryQuery;

import { App } from "@/constants";
import { array, enums, nullable, string, type } from "superstruct";

const GrowaveFeaturesBodySchema = type({
    moneyFormat: string(),
    moneyWithCurrencyFormat: string(),
    shopCurrency: string(),
    availableApps: array(enums(Object.values(App))),
    storefrontApiAccessToken: nullable(string()),
    countryIsoCode: string(),
    languageIsoCode: string(),
    currencyIsoCode: string(),
    gwI18nLocale: string(),
});

export default GrowaveFeaturesBodySchema;

import { Store, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewsSettingsEntity from "../entities/ReviewsSettingsEntity";

export type ReviewsSettingsState = {
    settings: ReviewsSettingsEntity | null;
};

export function createInitialState(): ReviewsSettingsState {
    return {
        settings: null,
    };
}

@injectable()
@StoreConfig({ name: "reviews_settings" })
class ReviewsSettingsStore extends Store<ReviewsSettingsState> {
    constructor() {
        super(createInitialState());
    }
}

export default ReviewsSettingsStore;

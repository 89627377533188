export class ShopifyUrlUtils {
    private LINK_REGEX = /^https?:\/\//gm;
    private LOCALE_REGEX = /^\/([a-z]{2,3}(-[a-z]{2})?\/)?$/gm;

    constructor(public readonly locale: string) {
        if (!this.LOCALE_REGEX.test(locale)) {
            throw new Error('Invalid argument "locale"');
        }
    }

    /**
     * Added a language preffix to url
     */
    withLocale(link: string): string {
        if (this.LINK_REGEX.test(link))
            throw new Error("Link should be relative");
        link = link.replace(/^\/+/g, "");
        return this.locale + link;
    }
}

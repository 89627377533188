import {
    array,
    boolean,
    integer,
    literal,
    nullable,
    number,
    object,
    optional,
    string,
    type,
    union,
} from "superstruct";

export const GrowaveProductLiquidResponseSchema = type({
    id: number(),
    title: string(),
    handle: string(),
    description: string(),
    published_at: string(),
    created_at: string(),
    images: array(string()),
    featured_image: string(),
    media: array(
        type({
            alt: nullable(string()),
            id: number(),
            position: number(),
            src: string(),
            media_type: union([literal("image")]),
        })
    ),
    options: array(string()),
    variants: array(
        type({
            id: integer(),
            title: string(),
            name: string(),
            option1: nullable(string()),
            option2: nullable(string()),
            option3: nullable(string()),
            inventory_management: nullable(union([literal("shopify")])),
            price: number(),
            compare_at_price: nullable(number()),
            available: boolean(),
            quantity_rule: optional(
                object({
                    min: number(),
                    max: nullable(number()),
                    increment: number(),
                })
            ),
            featured_image: nullable(
                type({
                    id: number(),
                    product_id: number(),
                    position: number(),
                })
            ),
        })
    ),
});

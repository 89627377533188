import ReviewEntity from "@apps/reviews/entities/ReviewEntity";
import {
    ActiveState,
    EntityState,
    EntityStore,
    StoreConfig,
} from "@datorama/akita";
import { injectable } from "tsyringe";

export interface ReviewsGalleryState
    extends EntityState<ReviewEntity, number>,
        ActiveState {}

function createInitialState(): ReviewsGalleryState {
    return {
        active: null,
    };
}

@injectable()
@StoreConfig({ name: "reviews_gallery_store" })
class ReviewsGalleryStore extends EntityStore<ReviewsGalleryState> {
    constructor() {
        super(createInitialState());
    }
}

export default ReviewsGalleryStore;

import {
    Infer,
    enums,
    literal,
    nullable,
    number,
    object,
    string,
    type,
    union,
} from "superstruct";

const BrandingThemeColorSchema = union([
    literal("primaryColor"),
    literal("secondaryColor"),
]);

const BrandingFontColorSchema = union([literal("#ffffff"), literal("#1a1a1a")]);

export const BrandingOptionsSchema = object({
    login: object({
        brand: string(),
        text: string(),
        background: string(),
    }),
    reviews: object({
        reviews_main_color: string(),
        reviews_text_color: string(),
        reviews_stars_color: string(),
    }),
    rewards: object({
        rewardsPopUpWidget: object({
            programName: string(),
            theme: object({
                primaryColor: string(),
                secondaryColor: string(),
            }),
            banner: object({
                themeColor: BrandingThemeColorSchema,
                fontColor: BrandingFontColorSchema,
                image: nullable(string()),
            }),
            buttons: object({
                themeColor: BrandingThemeColorSchema,
                fontColor: BrandingFontColorSchema,
            }),
            content: object({
                iconsThemeColor: BrandingThemeColorSchema,
                linksThemeColor: BrandingThemeColorSchema,
            }),
            launcher: object({
                themeColor: BrandingThemeColorSchema,
                fontColor: BrandingFontColorSchema,
                widgetPosition: union([literal("BR"), literal("BL")]),
            }),
        }),
    }),
    wishlist: object({
        wishlistThemeSettings: object({
            primaryColor: string(), // hex
            secondaryColor: string(), // hex
        }),
        wishlistIconSettings: object({
            heartColor: string(), // hex
            backgroundColor: string(), // black | white (hex)
        }),
        wishlistButtonSettings: object({
            color: enums(["primaryColor", "secondaryColor"]), // primary | secondary
            borderRadius: number(), // number
            fontColor: string(), // black | white (hex)
            style: enums(["outlined", "filled"]), // outline | filled
        }),
        wishlistPopUpWidgetAndPageSettings: object({
            popupWidgetPosition: enums(["L", "R"]), // left | right
            launcherBackgroundColor: string(), // black | white (hex)
            launcherFontColor: string(), // black | white (hex)
            launcherIconBackgroundColor: enums([
                "primaryColor",
                "secondaryColor",
            ]), // primary | secondary
            buttonsColor: enums(["primaryColor", "secondaryColor"]), // primary | secondary
            buttonsFontColor: string(), // black | white (hex)
            buttonsStyle: enums(["outlined", "filled"]), // outline | filled
            buttonsBorderRadius: number(), // number
            linkColor: enums(["primaryColor", "secondaryColor"]), // primary | secondary
        }),
    }),
});

export const AdditionalInfoBrandingSchema = type({
    GW_BRANDING_OPTIONS: BrandingOptionsSchema,
});

export type AdditionalInfoBranding = Infer<typeof AdditionalInfoBrandingSchema>;

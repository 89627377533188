// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gw-widget-placeholder-LC-wrapper {
    position: fixed;
    z-index: var(--gw-z-index-widget-placeholder);
    top: 50%;
    left: 0;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left top;
}

.gw-widget-placeholder-LC {
    display: flex;
    gap: 50px;
}

.gw-widget-placeholder-RC-wrapper {
    position: fixed;
    z-index: var(--gw-z-index-widget-placeholder);
    top: 50%;
    right: 0;
    transform: rotate(90deg) translateX(50%);
    transform-origin: right top;
}

.gw-widget-placeholder-RC {
    display: flex;
    gap: 50px;
    transform: rotate(180deg);
}

.gw-widget-placeholder-BR-wrapper {
    position: fixed;
    right: 45px;
    left: auto;
    bottom: 10px;
    z-index: var(--gw-z-index-widget-placeholder);
}

.gw-widget-placeholder-BL-wrapper {
    position: fixed;
    right: auto;
    left: 45px;
    bottom: 10px;
    z-index: var(--gw-z-index-widget-placeholder);
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/widgets-placeholders.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,6CAA6C;IAC7C,QAAQ;IACR,OAAO;IACP,0CAA0C;IAC1C,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,eAAe;IACf,6CAA6C;IAC7C,QAAQ;IACR,QAAQ;IACR,wCAAwC;IACxC,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,UAAU;IACV,YAAY;IACZ,6CAA6C;AACjD;;AAEA;IACI,eAAe;IACf,WAAW;IACX,UAAU;IACV,YAAY;IACZ,6CAA6C;AACjD","sourcesContent":[".gw-widget-placeholder-LC-wrapper {\n    position: fixed;\n    z-index: var(--gw-z-index-widget-placeholder);\n    top: 50%;\n    left: 0;\n    transform: rotate(-90deg) translateX(-50%);\n    transform-origin: left top;\n}\n\n.gw-widget-placeholder-LC {\n    display: flex;\n    gap: 50px;\n}\n\n.gw-widget-placeholder-RC-wrapper {\n    position: fixed;\n    z-index: var(--gw-z-index-widget-placeholder);\n    top: 50%;\n    right: 0;\n    transform: rotate(90deg) translateX(50%);\n    transform-origin: right top;\n}\n\n.gw-widget-placeholder-RC {\n    display: flex;\n    gap: 50px;\n    transform: rotate(180deg);\n}\n\n.gw-widget-placeholder-BR-wrapper {\n    position: fixed;\n    right: 45px;\n    left: auto;\n    bottom: 10px;\n    z-index: var(--gw-z-index-widget-placeholder);\n}\n\n.gw-widget-placeholder-BL-wrapper {\n    position: fixed;\n    right: auto;\n    left: 45px;\n    bottom: 10px;\n    z-index: var(--gw-z-index-widget-placeholder);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import { gql } from "graphql-tag";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { create } from "superstruct";
import { singleton } from "tsyringe";

import ProductEntity from "../entities/ProductEntity";
import { ProductsApiServiceInterface } from "./ProductsApiServiceInterface";
import { ShopifyGraphQLProductResponseSchema } from "./responses/ShopifyGraphQLProductResponseSchema";

const globalIdRegEx = /gid:\/\/shopify\/(.+)?\/(\d+)/gi;
export const parseGlobalId = (id: string) => {
    globalIdRegEx.lastIndex = 0;
    const match = globalIdRegEx.exec(id);
    if (!match) {
        throw new Error("Unknown id syntax");
    }
    return parseInt(match[2]);
};

const productFragmentDocument = gql`
    fragment ProductInfo on Product {
        id
        title
        description
        options(first: 5) {
            id
            name
            values
        }
        featuredImage {
            id
            url
            altText
        }
        variants(first: 20) {
            nodes {
                id
                title
                image {
                    id
                    url
                    altText
                }
                price {
                    amount
                    currencyCode
                }
                compareAtPrice {
                    amount
                    currencyCode
                }
                selectedOptions {
                    name
                    value
                }
                currentlyNotInStock
                availableForSale
                quantityAvailable
            }
        }
        images(first: 20) {
            nodes {
                id
                url
                altText
            }
        }
        handle
    }
`;

const productsQueryDocument = (
    productIds: number[],
    countryIsoCode: string,
    languageIsoCode: string
) => {
    const productQueries = productIds.map(productId => {
        return `_${productId}: product(id: "gid://shopify/Product/${productId}") {
            ...ProductInfo
        }`;
    });
 
    return gql`
        query productInfo @inContext(country: ${countryIsoCode}, language: ${languageIsoCode}) {
            ${productQueries.join(",")}
        }
    `;
};

@singleton()
export class ShopifyGraphQLProductsApiService implements ProductsApiServiceInterface {
    constructor(
        private readonly graphQLApiClient: GraphQLApiClient,
        private readonly countryIsoCode: string,
        private readonly languageIsoCode: string
    ) {}

    public getProducts(ids: number[]): Observable<ProductEntity[]> {
        const document = gql`
            ${productFragmentDocument},
            ${productsQueryDocument(
                ids,
                this.countryIsoCode,
                // iso формат не подходит
                // https://shopify.dev/docs/api/storefront/2024-01/enums/LanguageCode
                this.languageIsoCode.replace("-", "_")
            )}
        `;

        return this.graphQLApiClient.request<Record<string, ProductEntity>>(document).pipe(
            map((data): ProductEntity[] => {
                const response = create(
                    data,
                    ShopifyGraphQLProductResponseSchema
                );

                return ids.map(id => {
                    const responseItem = response[`_${id}`];

                    return {
                        id: parseGlobalId(responseItem.id),
                        title: responseItem.title,
                        description: responseItem.description,
                        featuredImage: responseItem.featuredImage
                            ? {
                                id: parseGlobalId(
                                        responseItem.featuredImage.id
                                ),
                                    url: responseItem.featuredImage.url,
                                    altText: responseItem.featuredImage.altText,
                            }
                            : null,
                        images: responseItem.images.nodes.map((image) => ({
                            ...image,
                            id: parseGlobalId(image.id),
                        })),
                        handle: responseItem.handle,
                        options: responseItem.options.map((option) => ({
                            name: option.name,
                            values: option.values,
                        })),
                        variants: responseItem.variants.nodes.map(
                            (variant) => ({
                                id: parseGlobalId(variant.id),
                                image: variant.image
                                    ? {
                                        id: parseGlobalId(variant.image.id),
                                        url: variant.image.url,
                                        altText: variant.image.altText,
                                    }
                                    : null,
                                title: variant.title,
                                price: {
                                    amount: parseFloat(variant.price.amount),
                                    currency: variant.price.currencyCode,
                                },
                                compareAtPrice: variant.compareAtPrice
                                    ? {
                                        amount: parseFloat(
                                            variant.compareAtPrice.amount
                                        ),
                                        currency:
                                            variant.compareAtPrice.currencyCode,
                                    }
                                    : null,
                                options: variant.selectedOptions,
                                currentlyNotInStock: variant.currentlyNotInStock,
                                availableForSale: variant.availableForSale,
                                quantityAvailable: variant.quantityAvailable,
                            })
                        ),
                    };
                });
            })
        );
    }
}

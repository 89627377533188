import {
    ProductState,
    ProductStore,
} from "@apps/iloom_customization/modules/products/stores/ProductStore";
import { QueryEntity } from "@datorama/akita";
import { map } from "rxjs/operators";
import { injectable } from "tsyringe";

@injectable()
export class ProductQuery extends QueryEntity<ProductState> {
    public constructor(private readonly productStore: ProductStore) {
        super(productStore);
    }

    all$ = this.selectAll();

    public selectProduct(productId: number) {
        return this.selectAll().pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => product || null)
        );
    }

    public selectProductAndVariant(
        productId: number,
        variantId: number | null
    ) {
        return this.selectAll().pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => ({
                product: product,
                variant: product?.variants.find((variant) => {
                    if (!variantId) {
                        return true;
                    }
                    return variant.id === variantId;
                }),
            }))
        );
    }

    public selectFirstVariantOfProduct(productId: number) {
        return this.selectProduct(productId).pipe(
            map((product) => {
                if (product?.variants.length) {
                    return product.variants[0];
                }

                return null;
            })
        );
    }
}
